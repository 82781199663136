import React from "react"
import { TrainingVideo } from "@utils/globalTypes"
import * as s from "./ProductTrainingAndCertifications.module.scss"

type Props = {
  trainingVideo: TrainingVideo
}

const ProductTrainingAndCertifications: React.FC<Props> = ({
  trainingVideo,
}) => {
  return (
    <>
      <div>
        <h2 className={s.title}>{trainingVideo.title}</h2>
        <div className={s.box}>
          <div
            dangerouslySetInnerHTML={{ __html: trainingVideo.content }}
          ></div>
        </div>
      </div>
    </>
  )
}

export default ProductTrainingAndCertifications
