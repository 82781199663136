import { ProductOption, product_options } from "@utils/globalTypes"
import React, { FC } from "react"

import * as s from "./ProductAvailablePanels.module.scss"

type Props = {
  panels: string
  options?: ProductOption[]
}

const ProductAvailablePanels: FC<Props> = ({ panels = "", options }) => {
  const panelSizes = options.find(
    (option) => option.name === product_options.panel_size
  )

  const formatedPanels =
    panelSizes &&
    panelSizes.values.sort(
      (a, b) =>
        parseInt(a) - parseInt(b) || a.length - b.length || a.localeCompare(b)
    )

  const optionValues = options.find(
    (option) => option.name === product_options.configuration
  )

  const configurationValues = optionValues ? optionValues.values : []

  const configOptions = configurationValues.map((config) => {
    const configFormatOptions = config.includes("|")
      ? config.split("|")[1].replace(/\s/g, "").replace("+", "/").trim()
      : config.replace(/\s/g, "").trim()

    return configFormatOptions
  })

  const testForOptions = Array.from(
    new Set(
      configOptions
        .join("/")
        .split("/")
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    )
  ).join(" / ")

  return (
    <div className={s.container}>
      <h2 className={s.title}>Available Panels</h2>
      <div className={s.panelsContainer}>
        {testForOptions && (
          <p className={s.tests}>Tests for: {testForOptions}</p>
        )}
        <div className={s.panelSizeContainer}>
          {formatedPanels &&
            formatedPanels.map((panel, index) => (
              <span className={s.panel} key={index}>
                {panel}
              </span>
            ))}
        </div>
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: panels }} />
      </div>
    </div>
  )
}
export default ProductAvailablePanels
