import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { ProductOption, ProductVariant } from "@utils/globalTypes"
import { Accordion } from "react-bootstrap"
import BulkOrderForm from "./components/BulkOrderForm"
import BulkOrderingForm from "./components/BulkOrderingForm"
import { ShopContext } from "@contexts/shop"
import SuccessAlert from "@components/common/SuccessAlert"
import * as s from "./ProductActions.module.scss"
import { useLocation } from "@reach/router"

type Props = {
  variants: ProductVariant[]
  options: ProductOption[]
  buyNow: string
  productName: string
}

const getLowerVariant = (variants: ProductVariant[]) => {
  const sortedVariants = variants.sort((a, b) => {
    const aVal = parseInt(a.price)
    const bVal = parseInt(b.price)
    return aVal < bVal ? -1 : aVal === bVal ? 0 : 1
  })
  return sortedVariants[0]
}

const ProductActions: React.FC<Props> = ({
  buyNow,
  options,
  variants,
  productName,
}) => {
  const { addProductToCart } = useContext(ShopContext)
  const [alert, setAlert] = useState({ show: false, message: "" })
  const [variant, setVariant] = useState(getLowerVariant(variants))
  const [quantity, setQuantity] = useState(0)
  const [country, setCountry] = useState("")
  const [activeKey, setActiveKey] = useState<string>(null)
  const location = useLocation()

  // Shows the Buy Now section at all
  const isBuyNow = buyNow && buyNow === "True" ? true : false

  useEffect(() => {
    const params = new URLSearchParams(location.search?.substring(1) || "")
    // shows the Buy Now section open by default
    const buyNowOpen = params.get("buynow") ? true : false
    setActiveKey(isBuyNow ? (buyNowOpen ? "1" : "0") : "0")
  }, [location])

  const submitProduct = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (quantity > 0) {
        await addProductToCart(variant.shopifyId, quantity)
        navigate("/cart")
      }
    } catch (err) {
      setAlert({ show: true, message: err?.message || "" })
      setTimeout(() => {
        setAlert({ show: false, message: "" })
      }, 2000)
    }
  }

  const changeConfiguration = (variant: ProductVariant) => setVariant(variant)

  const changePanelSize = (variant: ProductVariant) => setVariant(variant)

  const changeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.target.value)
    setQuantity(newQuantity)
  }

  const changeCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCountry = e.target.value
    setCountry(newCountry)
  }

  const handleAccordionSelect = (eventKey: string) => setActiveKey(eventKey)

  const hideProductForm = () => {
    switch (productName) {
      case "HairConfirm Hair Follicle Drug Test Collection Kit":
      case "Equate Home Drug Test":
      case "Rite Aid Home Drug Test":
        return null

      default:
        return (
          <BulkOrderingForm
            productName={productName}
            eventKey="0"
            title="Ordering in Bulk?"
            subtitle="Existing Customer? Email ss@confirmbiosciences.com for a Re-Order or Quote"
            country={country}
            onChangeQuantity={changeQuantity}
            onChangeCountry={changeCountry}
            options={options}
            toggleText="Request A Quote"
          />
        )
    }
  }

  return (
    <div className={s.container}>
      <Accordion
        bsPrefix="default"
        activeKey={activeKey}
        onSelect={handleAccordionSelect}
      >
        {isBuyNow && variants.length > 0 && (
          <BulkOrderForm
            price={variant?.price}
            eventKey="1"
            title="Ready to Order?"
            onSubmit={submitProduct}
            onChangeQuantity={changeQuantity}
            onChangePanelSize={changePanelSize}
            onChangeConfiguration={changeConfiguration}
            variants={variants}
            toggleText="Buy Now"
          />
        )}
        {hideProductForm()}
      </Accordion>
      <SuccessAlert
        title="Oops!"
        content={alert.message}
        show={alert.show}
        variant="danger"
      />
    </div>
  )
}

export default ProductActions
