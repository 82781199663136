import React, { FC } from "react"
import { Accordion } from "react-bootstrap"
import { ProductPrice } from "@utils/globalTypes"
import * as s from "./ProductPrice.module.scss"

type Props = {
  eventKey: string
  price: ProductPrice
}

const ProductPriceContainer: FC<Props> = ({ price, eventKey }) => {
  const fixedPrice = parseFloat(price)
  const perUnitPrice = fixedPrice / 25

  return (
    <Accordion.Toggle style={{ outline: "none" }} eventKey={eventKey}>
      <div className={s.container}>
        <h1 className={s.price}>${fixedPrice.toFixed(2)}</h1>
        <span className={s.text}>${perUnitPrice.toFixed(2)} per unit</span>
      </div>
      <div className={s.container}>
        <p className={s.casesText}>Sold in cases of 25 Tests</p>
      </div>
    </Accordion.Toggle>
  )
}

export default ProductPriceContainer
