import { Col, Form, Accordion, Card } from "react-bootstrap"
import { useLocation } from "@reach/router"
import { ProductPrice, ProductVariant } from "@utils/globalTypes"
import { BulkOrderFormHeader } from "./BulkOrderFormHeader"
import React, { FC, useContext, useEffect, useState } from "react"
import FullyRoundedDarkButton from "@components/common/Buttons/FullyRoundedDarkButton"
import * as s from "./BulkOrderForm.module.scss"
import ProductPriceContainer from "../ProductPrice"
import { AccordionContext } from "react-bootstrap"

const getOptionsFromVariants = (variants: ProductVariant[]) => {
  const optionsList: { panelNumber: string; configurations: string[] }[] = []

  variants.forEach((variant) => {
    const [panelNumber, rest] = variant.title.split(" / ")
    const configuration = rest.split(" | ")[1]
    const panelIndex = optionsList.findIndex(
      (o) => o.panelNumber === panelNumber
    )
    if (panelIndex !== -1) {
      optionsList[panelIndex].configurations.push(configuration)
    } else {
      optionsList[optionsList.length] = {
        panelNumber,
        configurations: [configuration],
      }
    }
  })

  // sort the options by panelNumber ascending
  const sortedOptionsList = optionsList.sort((a, b) => {
    const aVal = parseInt(a.panelNumber.split(" ")[0])
    const bVal = parseInt(b.panelNumber.split(" ")[0])
    return aVal < bVal || aVal === bVal ? 0 : 1
  })
  return sortedOptionsList
}

type Props = {
  eventKey: string
  title: string
  toggleText: string
  price: ProductPrice
  variants: ProductVariant[]
  onChangeConfiguration: (variant: ProductVariant) => any
  onChangePanelSize: (variant: ProductVariant) => any
  onChangeQuantity: (e: React.ChangeEvent<HTMLInputElement>) => any
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => any
}

const BulkOrderForm: FC<Props> = ({
  price,
  title,
  eventKey,
  onSubmit,
  toggleText,
  onChangeQuantity,
  onChangePanelSize,
  onChangeConfiguration,
  variants,
}) => {
  const location = useLocation()
  const [configurations, setConfigurations] = useState<string[]>([])
  const [panelNumber, setPanelNumber] = useState<string>("")
  const [optionsList, setOptionList] = useState([])

  useEffect(() => {
    setOptionList(getOptionsFromVariants(variants))
    const defaultVariant = variants.find(
      (v) => v.price === price
    )
    const params = new URLSearchParams(location.search?.substring(1) || "")
    const panelNum =
      params.get("panelnumber") || defaultVariant.title.split(" / ")[0]

    setPanelNumber(panelNum)
  }, [])

  useEffect(() => {
    const option = optionsList.find(
      (option) => option.panelNumber === panelNumber
    )
    setConfigurations(option?.configurations || [])
  }, [panelNumber])

  const quantities = Array.from(Array(50).keys())
  const currentEventKey = useContext(AccordionContext)
  const isCurrentEventKey = currentEventKey === eventKey
  const cardStyle = isCurrentEventKey ? s.cardOpen : s.card
  const cardBodyStyle = isCurrentEventKey ? s.openCardBody : s.cardBody

  const handlePanelSelectChange = (event) => {
    const selectedPanelNumber = event.target.value
    setPanelNumber(selectedPanelNumber)
    const filteredOptions = optionsList.find(
      (o) => o.panelNumber === selectedPanelNumber
    )
    setConfigurations(filteredOptions.configurations)
    const variant = variants.find((v) =>
      v.title.startsWith(selectedPanelNumber)
    )
    onChangePanelSize(variant)
  }

  const handleConfigurationChange = (event) => {
    const configuration = event.target.value
    const variant = variants.find((v) => v.title.includes(configuration))
    onChangeConfiguration(variant)
  }

  return (
    <div className={s.container}>
      <Card className={cardStyle}>
        {isCurrentEventKey ? (
          <ProductPriceContainer price={price} eventKey="0" />
        ) : (
          <BulkOrderFormHeader
            title={title}
            toggleText={toggleText}
            eventKey={eventKey}
          />
        )}
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className={cardBodyStyle}>
            <Form onSubmit={onSubmit}>
              <Form.Row>
                <Col xs={12} sm={6}>
                  <Form.Group>
                    <Form.Control
                      value={panelNumber}
                      as="select"
                      id="panelNumber"
                      className={s.formControl}
                      required
                      onChange={handlePanelSelectChange}
                    >
                      <option value="panelNumber" disabled>
                        How Many Drugs?
                      </option>
                      {optionsList &&
                        optionsList.map((option, i) => (
                          <option key={i} value={option.panelNumber}>
                            {option.panelNumber}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group>
                    <Form.Control
                      id="configurations"
                      as="select"
                      defaultValue="configuration"
                      className={s.formControl}
                      onChange={handleConfigurationChange}
                      required
                    >
                      <option value="configuration" disabled>
                        Configurations
                      </option>
                      {configurations &&
                        configurations.map((option, i) => (
                          <option value={option} key={i}>
                            {option}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label className={s.formLabel} htmlFor="quantities">
                      Quantities
                    </Form.Label>
                    <Form.Control
                      id="quantities"
                      className={s.formControl}
                      defaultValue="quantities"
                      required
                      onChange={onChangeQuantity}
                      as="select"
                    >
                      <option value="quantities" disabled>
                        Select Quantity
                      </option>
                      {quantities.map((q, i) => (
                        <option value={i + 1} key={i}>
                          {i + 1} Cases
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
              <FullyRoundedDarkButton
                type="submit"
                style={{ width: "100%", marginTop: 5 }}
              >
                Buy Now
              </FullyRoundedDarkButton>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  )
}

export default BulkOrderForm
