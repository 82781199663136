import { Col, Container, Row } from "react-bootstrap"
import { Product, metafield_keys } from "@utils/globalTypes"
import React, { FC } from "react"

import ProductAvailablePanels from "@components/products/ProductAvailablePanels"
import ProductDescription from "@components/products/ProductDescription"
import * as s from "./PanelsAndDescription.module.scss"

type Props = {
  product: Product
}

const PanelsAndDescription: FC<Props> = ({ product }) => {
  const { descriptionHtml, metafields = [], options = [] } = product

  const panels = metafields.find((meta) => meta.key === metafield_keys.panels)
  return (
    <Container className={s.container}>
      <Row>
        <Col xs={12} lg={6}>
          <ProductAvailablePanels
            options={options}
            panels={panels && panels.value}
          />
        </Col>
        <Col xs={12} lg={6}>
          <ProductDescription description={descriptionHtml} />
        </Col>
      </Row>
    </Container>
  )
}
export default PanelsAndDescription
