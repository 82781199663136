import React, { FC, useContext } from "react"
import { Card, Accordion } from "react-bootstrap"
import { ProductOption } from "@utils/globalTypes"
import * as s from "./BulkOrderingForm.module.scss"
import { AccordionContext } from "react-bootstrap"
import { BulkOrderingFormHeader } from "./BulkOrderingFormHeader"
import ContactUs from "@components/common/ContactUs"

type Props = {
  title: string
  subtitle: string
  country: string
  eventKey: string
  toggleText: string
  productName: string
  options: ProductOption[]
  onChangeQuantity: (e: React.ChangeEvent<HTMLInputElement>) => any
  onChangeCountry: (e: React.ChangeEvent<HTMLInputElement>) => any
}

export const BulkOrderingForm: FC<Props> = ({
  title,
  subtitle,
  eventKey,
  toggleText,
}) => {
  const currentEventKey = useContext(AccordionContext)
  const isCurrentEventKey = currentEventKey === eventKey
  const cardStyle = isCurrentEventKey ? s.cardOpen : s.card
  const cardBodyStyle = isCurrentEventKey ? s.openCardBody : s.cardBody

  return (
    <div className={s.container}>
      <Card bsPrefix={cardStyle}>
        <BulkOrderingFormHeader
          eventKey={eventKey}
          title={title}
          subtitle={subtitle}
          toggleText={toggleText}
        />
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body bsPrefix={cardBodyStyle}>
            <ContactUs />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  )
}
