import { Col, Container, Row } from "react-bootstrap"
import React, { FC } from "react"

import Banner from "@components/common/Banner"
import * as s from "./BannerContainer.module.scss"

const BannerContainer: FC = () => {
  return (
    <div className={s.container}>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12}>
            <Banner />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default BannerContainer
