import { Col, Container, Row } from "react-bootstrap"
import React, { FC } from "react"

import ProductInstructions from "@components/products/ProductInstructions"
import { SanityProduct } from "@utils/globalTypes"
import * as s from "./ProcedureAndHowToResults.module.scss"

type Props = {
  sanityProduct: SanityProduct
}

const ProcedureAndHowToResults: FC<Props> = ({ sanityProduct }) => {
  if (!sanityProduct) {
    return <></>
  }

  const { collectionProcedure, howToReadResults } = sanityProduct

  return (
    <div className={s.sectionContainer}>
      <Container>
        <Row>
          {collectionProcedure && (
            <Col xs={12} lg={6}>
              <ProductInstructions
                title="Collection Procedure"
                instructions={collectionProcedure}
              />
            </Col>
          )}
          {howToReadResults && (
            <Col xs={12} lg={6}>
              <ProductInstructions
                title="How to Read Results"
                dark
                instructions={howToReadResults}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default ProcedureAndHowToResults
