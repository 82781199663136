import React, { FC } from "react"

type ImageMockupProps = {
  width?: number | string
  height?: number | string
  borderRadius?: string | number
  backgroundColor?: string
  className?: string
}

export const ImageMockup: FC<ImageMockupProps> = ({
  width,
  height,
  backgroundColor = "#E3E3E3",
  borderRadius = 0,
  className,
}) => {
  return (
    <div
      className={className}
      style={{ width, height, backgroundColor, borderRadius }}
    ></div>
  )
}
