import { Col, Container, Row } from "react-bootstrap"
import { SanityProduct } from "@utils/globalTypes"
import React, { FC } from "react"

import FAQQuestion from "@components/common/FAQ"
import * as s from "./FAQQuestionSection.module.scss"

type Props = {
  sanityProduct?: SanityProduct
  title: string
}

const FAQQuestionSection: FC<Props> = ({ title, sanityProduct }) => {
  if (!sanityProduct || !sanityProduct?.faqs || !sanityProduct?.faqs.length) {
    return <></>
  }

  const { faqs } = sanityProduct

  return (
    <Container className={s.container}>
      <Row>
        <Col xs={12}>
          <h2 className={s.title}>{title} FAQs</h2>
        </Col>
        <Col xs={12}>
          <Container>
            <Row>
              {faqs.map((question, index) => (
                <Col key={index} xs={12} md={6} lg={6}>
                  <FAQQuestion {...question} />
                </Col>
              ))}
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default FAQQuestionSection
