import { Col, Container, Row } from "react-bootstrap"
import React, { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { MOBILE_WIDTH } from "@utils/util"
import useResizeWidth from "@hooks/useResizeWidth"
import { GatsbyImage } from "gatsby-plugin-image"
import * as s from "./CutOffChart.module.scss"

const CutOffChartPage: FC = () => {
  const { width } = useResizeWidth()
  const { desktopCutOffChart, mobileCutOffChart } = useStaticQuery(query)
  const isMobile =
    typeof window !== "undefined" && width <= MOBILE_WIDTH ? true : false
  return (
    <Container>
      <Row className="flex-column">
        <Col xs={12}>
          <h2 className={s.title}>Cut Off Chart</h2>
        </Col>
        <Col xs={12}>
          {isMobile ? (
            <GatsbyImage
              image={mobileCutOffChart?.image?.asset?.gatsbyImageData}
              alt="confirm bioscience"
              style={{ maxHeight: "100%" }}
              imgStyle={{ objectFit: "contain" }}
            />
          ) : (
            <GatsbyImage
              image={desktopCutOffChart?.image?.asset?.gatsbyImageData}
              alt="confirm bioscience"
              style={{ maxHeight: "100%" }}
              imgStyle={{ objectFit: "contain" }}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

const query = graphql`
  query {
    desktopCutOffChart: sanityCutOffChart(
      handle: { current: { eq: "cutoff-desktop" } }
    ) {
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
    mobileCutOffChart: sanityCutOffChart(
      handle: { current: { eq: "cutoff-mobile" } }
    ) {
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`

export default CutOffChartPage
