// extracted by mini-css-extract-plugin
export var buyNow = "BulkOrderingForm-module--buyNow--Dk016";
export var card = "BulkOrderingForm-module--card--ExRtW";
export var cardBody = "BulkOrderingForm-module--cardBody--+I7fn";
export var cardHeader = "BulkOrderingForm-module--cardHeader--lI7Qc";
export var cardOpen = "BulkOrderingForm-module--cardOpen--aYrNP";
export var container = "BulkOrderingForm-module--container--s-ZLv";
export var ctas = "#226355";
export var fadeIn = "BulkOrderingForm-module--fadeIn--Llc0Y";
export var formControl = "BulkOrderingForm-module--formControl--S3StB";
export var formLabel = "BulkOrderingForm-module--formLabel--lqeSC";
export var grayBackground = "#f6f8fb";
export var openBuyNow = "BulkOrderingForm-module--openBuyNow--1DFYs";
export var openCardBody = "BulkOrderingForm-module--openCardBody--vXsb3";
export var openCardHeader = "BulkOrderingForm-module--openCardHeader--yLljo";
export var title = "BulkOrderingForm-module--title--4RWJj";