import React, { FC } from "react"
import { Resource } from "@utils/globalTypes"
import ResourceElement from "@components/common/ResourceElement"
import * as s from "./ProductResources.module.scss"

type Props = {
  resources: Resource[]
}

const ProductResources: FC<Props> = ({ resources }) => {
  if (!resources || !resources.length) return <></>

  return (
    <>
      <div>
        <h2 className={s.header}>Resources</h2>
        <div className={s.resourcesContainer}>
          {resources.map((resource, index) => (
            <ResourceElement key={index} resource={resource} />
          ))}
        </div>
      </div>
    </>
  )
}

export default ProductResources
