// extracted by mini-css-extract-plugin
export var buyNow = "BulkOrderForm-module--buyNow--YgnLx";
export var card = "BulkOrderForm-module--card--3BmBP";
export var cardBody = "BulkOrderForm-module--cardBody---cqoQ";
export var cardHeader = "BulkOrderForm-module--cardHeader--vR63D";
export var cardOpen = "BulkOrderForm-module--cardOpen--nlksM";
export var container = "BulkOrderForm-module--container--oe4Za";
export var ctas = "#226355";
export var formControl = "BulkOrderForm-module--formControl--9c3I9";
export var formLabel = "BulkOrderForm-module--formLabel--PP46v";
export var grayBackground = "#f6f8fb";
export var openBuyNow = "BulkOrderForm-module--openBuyNow---45TI";
export var openCardBody = "BulkOrderForm-module--openCardBody--uU4An";
export var openCardHeader = "BulkOrderForm-module--openCardHeader--SdlLj";
export var title = "BulkOrderForm-module--title--Uo4tE";