import React, { FC, useState } from "react"
import * as s from "./ProductImageAndVariants.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { ImageSharp } from "@utils/globalTypes"
import { ImageMockup } from "@components/mockups/mockups"

type Props = {
  images?: ImageSharp[]
}

const ProductImageAndVariants: FC<Props> = ({ images }) => {
  const [activeImage, setActiveImage] = useState(images[0])

  if (!images || !images.length || !images[0].localFile) {
    return <ImageMockup width={430} />
  }

  return (
    <>
      {activeImage?.localFile?.childImageSharp && (
        <GatsbyImage
          alt="confirm bio"
          image={activeImage?.localFile?.childImageSharp?.gatsbyImageData}
        />
      )}

      <div className={s.thumbnailsContainer}>
        {images.map((image) => (
          <div key={image.id} onClick={() => setActiveImage(image)}>
            {image?.localFile?.childImageSharp && (
              <GatsbyImage
                className={s.thumbnail}
                alt="confirm-bio"
                image={image?.localFile?.childImageSharp?.gatsbyImageData}
              />
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default ProductImageAndVariants
