import React, { FC } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Procedure } from "@utils/globalTypes"
import * as s from "./Instruction.module.scss"
import { BaseBlockContent } from "@utils/richText"

type Props = {
  instruction: Procedure
}

const Instruction: FC<Props> = ({ instruction }) => {
  const { title, image, _rawText } = instruction
  return (
    <div className={s.container}>
      <div className={s.imgContainer}>
        <GatsbyImage alt={image.alt} image={image?.asset?.gatsbyImageData} />
      </div>
      <div className={s.textsContainer}>
        <p className={s.title}>{title}</p>
        {_rawText && <BaseBlockContent blocks={_rawText} />}
      </div>
    </div>
  )
}

export default Instruction
