import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import {
  Metafield,
  Product,
  metafield_keys,
  ProductVariant,
} from "@utils/globalTypes"
import { MOBILE_WIDTH } from "@utils/util"
import ProductActions from "@components/products/ProductActions"
import ProductImageAndVariants from "@components/products/ProductImageAndVariants"
import * as s from "./Detail.module.scss"
import useResizeWidth from "@hooks/useResizeWidth"

type Props = {
  product: Product
}

const badgesMetafield = [
  metafield_keys.badge_1,
  metafield_keys.badge_2,
  metafield_keys.badge_3,
]

const getMetafieldsData = (metafields: Metafield[]) => {
  const bulletPoints = metafields.find(
    (meta) => meta.key === metafield_keys.bullet_points
  )
  const buyNow = metafields.find((meta) => meta.key === metafield_keys.buy_now)
  const badges = metafields.filter((meta) => badgesMetafield.includes(meta.key))

  return { bulletPoints, badges, buyNow }
}

const Details = ({ product }: Props) => {
  const { width } = useResizeWidth()
  const { title, images, metafields, variants, options } = product
  const { bulletPoints, buyNow, badges } = getMetafieldsData(metafields)
  const isMobile =
    typeof window !== "undefined" && width <= MOBILE_WIDTH ? true : false

  const availableVariants = (variants: ProductVariant[]) => {
    return variants && variants.filter((variant) => variant.availableForSale)
  }

  const renderBadges = (badges) =>
    badges.map((badge, i) => (
      <div key={i} className={s.circularImgsContainer}>
        <div dangerouslySetInnerHTML={{ __html: badge.value }} />
      </div>
    ))

  return (
    <Container className={s.container}>
      <Row className="justify-content-center">
        {isMobile ? (
          <>
            <Col xs={12}>
              <h1 className={s.title}>{title}</h1>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <ProductImageAndVariants images={images} />
            </Col>
            <Col xs={12} md={6} lg={8}>
              <Row>
                <Col xs={12} md={12} lg={6}>
                  <ProductActions
                    productName={title}
                    options={options}
                    variants={availableVariants(variants)}
                    buyNow={buyNow ? buyNow.value : "true"}
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  {bulletPoints && (
                    <div
                      dangerouslySetInnerHTML={{ __html: bulletPoints.value }}
                    />
                  )}
                </Col>
                <Col xs={12} className="d-flex justify-content-between">
                  {badges && renderBadges(badges)}
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <>
            <Col xs={12} md={6} lg={4}>
              <ProductImageAndVariants images={images} />
            </Col>
            <Col xs={12} md={6} lg={8}>
              <Row>
                <Col xs={12}>
                  <h1 className={s.title}>{title}</h1>
                </Col>
                <Col xs={12} md={12} lg={6}>
                  {bulletPoints && (
                    <div
                      className={s.bulletPoints}
                      dangerouslySetInnerHTML={{ __html: bulletPoints.value }}
                    />
                  )}
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <ProductActions
                    productName={title}
                    options={options}
                    variants={availableVariants(variants)}
                    buyNow={buyNow?.value}
                  />
                </Col>
                <Col xs={12} lg={6} className="d-flex justify-content-between">
                  {badges && renderBadges(badges)}
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </Container>
  )
}

export default Details
