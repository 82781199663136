import React, { FC } from "react"
import * as s from "./ProductDescription.module.scss"

type Props = {
  description: string
}

const ProductDescription: FC<Props> = ({ description }) => {
  return (
    <div className={s.descriptionContainer}>
      <div dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  )
}

export default ProductDescription
