import CutOffChart from "@components/common/CutOffChart"
import React from "react"
import * as s from "./CutOffChartSection.module.scss"

const CutOffChartSection = () => {
  return (
    <div className={s.container}>
      <CutOffChart />
    </div>
  )
}

export default CutOffChartSection
