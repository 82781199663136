import React, { FC } from "react"
import { graphql } from "gatsby"
import BannerContainer from "./BannerContainer"
import CutOffChartSection from "./CutOffChartSection"
import Detail from "./Detail"
import FAQQuestionSection from "./FAQQuestionSection"
import PanelsAndDescription from "./PanelsAndDescription"
import ProcedureAndHowToResults from "./ProcedureAndHowToResults"
import SEO from "@components/common/SEO"
import TrainingAndResources from "./TrainingAndResources"

import { Product, SanityProduct, ShopifyAdminAPI } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import { Container } from "react-bootstrap"
import * as s from "./Product.module.scss"

type Props = {
  data: {
    shopifyProduct: Product
    shopifyAdminAPI: ShopifyAdminAPI
    sanityProduct: SanityProduct
  }
  location: Location
}

const ProductPage: FC<Props> = ({
  data: { shopifyProduct: product, sanityProduct: sanityProduct },
  location,
}) => {
  const metadata = {
    location: location.href,
    title: product.title,
    description: product.description,
  }

  const covidCollections = [
    "covid19-instant-coronavirus-test-kit",
    /*
        {
          link: "/coronavirus-test/",
          text: "Antibody Test (International Use)",
        },
        {
          link: "/covid19-sars-antigen-rapid-swab-test/",
          text: "Antigen Test",
        },
        { link: "/covid19-solutions/", text: "PCR Test" },
        */
  ]

  return (
    <>
      <SEO {...metadata} />
      <Detail product={product} />
      <BannerContainer />
      <PanelsAndDescription product={product} />
      <TrainingAndResources sanityProduct={sanityProduct} />
      <ProcedureAndHowToResults sanityProduct={sanityProduct} />
      {!covidCollections.includes(product.handle) && <CutOffChartSection />}
      <Container>
        <BaseBlockContent
          className={s.textFieldContainer}
          blocks={sanityProduct?.textField}
        />
      </Container>
      <FAQQuestionSection sanityProduct={sanityProduct} title={product.title} />
    </>
  )
}

export default ProductPage

export const pageQuery = graphql`
  query ($handle: String!, $productType: String!) {
    shopifyProduct: shopifyProduct(handle: { eq: $handle }) {
      id
      title
      descriptionHtml
      handle
      productType
      variants {
        id
        title
        availableForSale
        shopifyId
        sku
        price
      }
      images {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      metafields {
        key
        value
      }
      options {
        name
        values
      }
    }
    sanityProduct: sanityProduct(productType: { eq: $productType }) {
      faqs {
        question
        _rawAnswer
      }
      textField: _rawTextField(resolveReferences: { maxDepth: 10 })
      trainingVideo {
        title
        content
      }
      howToReadResults {
        title
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        _rawText
      }
      collectionProcedure {
        title
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        _rawText
      }
      resources {
        ... on SanityLandingPage {
          _type
          title
          handle {
            current
          }
        }
        ... on SanityResourcePage {
          _type
          title
          slug {
            current
          }
          category {
            name
            slug {
              current
            }
          }
        }
      }
    }
  }
`
