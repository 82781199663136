import { Col, Container, Row } from "react-bootstrap"
import React, { FC } from "react"

import ProductResources from "@components/products/ProductResources"
import ProductTrainingAndCertifications from "@components/products/ProductTrainingAndCertifications"
import { SanityProduct } from "@utils/globalTypes"
import * as s from "./TrainingAndResources.module.scss"

type Props = {
  sanityProduct: SanityProduct
}

const TrainingAndResources: FC<Props> = ({ sanityProduct }) => {
  if (!sanityProduct) {
    return <></>
  }

  const { trainingVideo, resources } = sanityProduct

  return (
    <div className={s.container}>
      <Container>
        <Row>
          <Col xs={12} lg={6} className={s.trainingContainer}>
            {trainingVideo && (
              <ProductTrainingAndCertifications trainingVideo={trainingVideo} />
            )}
          </Col>
          <Col xs={12} lg={6}>
            {resources && <ProductResources resources={resources} />}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default TrainingAndResources
