import React, { FC } from "react"
import { Alert } from "react-bootstrap"
import * as s from "./SuccessAlert.module.scss"

type Props = {
  title?: string
  content?: string
  show: boolean
  variant?: string
}

const SuccessAlert: FC<Props> = ({
  title,
  content,
  show,
  variant = "success",
}) => {
  return (
    <Alert className={s.alert} show={show} variant={variant} transition>
      {title && <Alert.Heading>{title}</Alert.Heading>}
      <p>{content}</p>
    </Alert>
  )
}

export default SuccessAlert
