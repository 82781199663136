import React, { FC } from "react"

import Instruction from "./components/Instruction"
import { Procedure } from "@utils/globalTypes"
import classNames from "classnames"
import * as s from "./ProductInstructions.module.scss"

type Props = {
  title: string
  dark?: boolean
  instructions: Procedure[]
}

const ProductInstructions: FC<Props> = ({ title, dark, instructions }) => {
  const containerClasses = classNames(s.container, dark ? s.dark : "")
  return (
    <div className={containerClasses}>
      <h2 className={s.title}>{title}</h2>
      <div>
        {instructions.map((instruction, index) => {
          return (
            <div key={index} className={s.instructionContainer}>
              <Instruction instruction={instruction} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProductInstructions
