import React, { FC, useContext } from "react"
import * as s from "./BulkOrderForm.module.scss"
import { AccordionContext, Card } from "react-bootstrap"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = {
  title: string
  eventKey: string
  toggleText: string
}

export const BulkOrderFormHeader: FC<Props> = ({
  title,
  eventKey,
  toggleText,
}) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(eventKey)
  const isCurrentEventKey = currentEventKey === eventKey
  const cardToggleTextStyle = isCurrentEventKey ? s.openBuyNow : s.buyNow
  const cardHeaderStyle = isCurrentEventKey ? s.openCardHeader : s.cardHeader
  return (
    <Card.Header bsPrefix={cardHeaderStyle} onClick={decoratedOnClick}>
      <p className={s.title}>{title}</p>
      <p className={cardToggleTextStyle}>
        {toggleText}
        <FontAwesomeIcon icon={faArrowRight} />
      </p>
    </Card.Header>
  )
}
