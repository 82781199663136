// extracted by mini-css-extract-plugin
export var configurationsContainer = "ProductAvailablePanels-module--configurationsContainer--eQKLi";
export var container = "ProductAvailablePanels-module--container--GqFyd";
export var ctas = "#226355";
export var grayBackground = "#f6f8fb";
export var panel = "ProductAvailablePanels-module--panel--4LMRD";
export var panelLink = "ProductAvailablePanels-module--panelLink--7bpSo";
export var panelSizeContainer = "ProductAvailablePanels-module--panelSizeContainer--BipOC";
export var panelsContainer = "ProductAvailablePanels-module--panelsContainer--18TeF";
export var tests = "ProductAvailablePanels-module--tests--UzvcJ";
export var title = "ProductAvailablePanels-module--title--H7Fh0";